module.exports = [
  {
    label: "Id",
    id: "id",
    mapping: ["account id", "id", "account.id"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Label",
    id: "label",
    mapping: ["account label", "label", "account.label"],
    type: ["account"],
  },
  {
    label: "Segment/Technology",
    id: "segment_technology",
    mapping: [
      "segment/technology",
      "tech",
      "technology",
      "segment",
      "segment_technology",
      "account.segment_technology",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "NS ID",
    id: "nsId",
    mapping: ["nsId", "companyId", "company id", "account.nsId"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Zoominfo Contact ID",
    id: "zoomInfoContactId",
    mapping: [
      "zoomInfoContactId",
      "zoominfo contact id",
      "contact id",
      "contact_id",
      "record id",
      "account.zoomInfoContactId",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Zoominfo Company Name",
    id: "zoomInfoName",
    mapping: [
      "zoomInfoCompanyName",
      "zoominfo company name",
      "zoomInfoName",
      "account.zoomInfoName",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "CompanyName",
    id: "name",
    mapping: [
      "companyName",
      "company_companyname",
      "company name",
      "account name",
      "name",
      "account.name",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Company Website",
    id: "website",
    mapping: [
      "companyWebsite",
      "company_website",
      "website",
      "domain",
      "url",
      "url/domain",
      "account.website",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "LI Location",
    id: "locationLI",
    mapping: [
      "liLocation",
      "linkedin location",
      "locationLI",
      "account.locationLI",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Address1 HQ",
    id: "addressHQ.address1HQ",
    mapping: [
      "address1HQ",
      "address_1HQ",
      "address 1HQ",
      "company street address",
      "addressHQ",
      "account.addressHQ",
      "addressHQ.address1HQ",
      "account.addressHQ.address1HQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Address2 HQ",
    id: "addressHQ.address2HQ",
    mapping: [
      "address2HQ",
      "address_2HQ",
      "address 2HQ",
      "addressHQ.address2HQ",
      "account.addressHQ.address2HQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "City HQ",
    id: "addressHQ.cityHQ",
    mapping: [
      "cityHQ",
      "city_HQ",
      "city HQ",
      "company city",
      "addressHQ.cityHQ",
      "account.addressHQ.cityHQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "State HQ",
    id: "addressHQ.stateHQ",
    mapping: [
      "stateHQ",
      "state_HQ",
      "state HQ",
      "company state",
      "addressHQ.stateHQ",
      "account.addressHQ.stateHQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Zip HQ",
    id: "addressHQ.zipCodeHQ",
    mapping: [
      "zipHQ",
      "zip_HQ",
      "zip HQ",
      "company zip code",
      "addressHQ.zipHQ",
      "account.addressHQ.zipCodeHQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Country HQ",
    id: "addressHQ.countryHQ",
    mapping: [
      "countryHQ",
      "country_HQ",
      "country HQ",
      "company country",
      "addressHQ.countryHQ",
      "account.addressHQ.countryHQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Phone HQ",
    id: "phoneHQ",
    mapping: [
      "phoneHQ",
      "phone_HQ",
      "phone HQ",
      "company HQ phone",
      "account.phoneHQ",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Email",
    id: "email",
    mapping: ["email", "account.email"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Disposition",
    id: "disposition",
    mapping: ["disposition", "account.disposition"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Source",
    id: "source",
    mapping: ["source", "account.source"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Linkedin URL",
    id: "linkedInUrl",
    mapping: [
      "CompanyLinkedinURL",
      "Company Linkedin URL",
      "Company LI URL",
      "CompanyLIURL",
      "linkedInUrl",
      "account.linkedInUrl",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Company Description",
    id: "description",
    mapping: [
      "CompanyDescription",
      "Company_Description",
      "Company Description",
      "description",
      "account.description",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Industry",
    id: "industry",
    mapping: [
      "industry",
      "primary industry",
      "main industry",
      "account.industry",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Sub - Industry",
    id: "subIndustry",
    mapping: [
      "sub-industry",
      "primary sub-industry",
      "subIndustry",
      "account.subIndustry",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Revenue",
    id: "revenue",
    mapping: ["revenue", "account.revenue"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Revenue (M/B/K)",
    id: "revenue_M_B_K",
    mapping: [
      "revenue(m/b/k)",
      "annual revenue",
      "revenue_M_B_K",
      "account.revenue_M_B_K",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Range (LI)",
    id: "employeeRangeLI",
    mapping: [
      "employeeRange(li)",
      "employee range",
      "employeeRangeLI",
      "account.employeeRangeLI",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Size",
    id: "employeeSize",
    mapping: ["employeeSize", "account.employeeSize"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Size (LI)",
    id: "employeeSizeLI",
    mapping: [
      "employeeSize(li)",
      "employee size (li)",
      "employeeSizeLI",
      "account.employeeSizeLI",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Source (LI)",
    id: "employeeSourceLI",
    mapping: [
      "employeeSource(li)",
      "employee source (li)",
      "employeeSourceLI",
      "account.employeeSourceLI",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Size (Z+)",
    id: "employeeSizeZ_plus",
    mapping: [
      "employeeSize(z+)",
      "employee size (z+)",
      "employeeSizeZ_plus",
      "account.employeeSizeZ_plus",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee source(Z+)",
    id: "employeeSourceZ_plus",
    mapping: [
      "employeeSource(z+)",
      "employee source (z+)",
      "employeeSourceZ_plus",
      "account.employeeSourceZ_plus",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Size Final Bucket",
    id: "employeeSizeFinalBucket",
    mapping: [
      "employeeSizeFinalBucket",
      "Emp Size",
      "Employee Size",
      "Head Count",
      "account.employeeSizeFinalBucket",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Employee Size (Others)",
    id: "employeeSize_others",
    mapping: [
      "employeeSize(others)",
      "employee size (others)",
      "employeeSize_others",
      "account.employeeSize_others",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Comments",
    id: "comments",
    mapping: ["comments", "account.comments"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Upper Revenue",
    id: "upperRevenue",
    mapping: ["upperRevenue", "account.upperRevenue"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Lower Revenue",
    id: "lowerRevenue",
    mapping: ["lowerRevenue", "account.lowerRevenue"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Upper Employee Size",
    id: "upperEmployeeSize",
    mapping: ["upperEmployeeSize", "account.upperEmployeeSize"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Lower Employee Size",
    id: "lowerEmployeeSize",
    mapping: ["lowerEmployeeSize", "account.lowerEmployeeSize"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "SIC Code",
    id: "sicCode",
    mapping: ["sicCode", "sic code", "sic", "account.sicCode"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "SIC Description",
    id: "sicDescription",
    mapping: ["sicDescription", "sic description", "account.sicDescription"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "NAICS Code",
    id: "naicsCode",
    mapping: ["naicsCode", "naics code", "naics", "account.naicsCode"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "NAICS Description",
    id: "naicsDescription",
    mapping: [
      "naicsDescription",
      "naics description",
      "account.naicsDescription",
    ],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Research Status",
    id: "researchStatus",
    mapping: ["researchStatus", "account.researchStatus"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Calling Status",
    id: "callingStatus",
    mapping: ["callingStatus", "account.callingStatus"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Compliance Status",
    id: "complianceStatus",
    mapping: ["complianceStatus", "account.complianceStatus"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Compliance Comments",
    id: "complianceComments",
    mapping: ["complianceComments", "account.complianceComments"],
    type: ["account"],
  },
  {
    label: "Domain",
    id: "domain",
    mapping: ["domain", "account.domain"],
    type: ["account"],
  },
  {
    label: "Stage",
    id: "stage",
    mapping: ["stage", "account.stage"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Parent Domain",
    id: "parentDomain",
    mapping: ["parentDomain", "account.parentDomain"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Type",
    id: "type",
    mapping: ["type", "account.type", "company type"],
    type: ["account"],
  },
  {
    label: "Custom Field 1",
    id: "custom1",
    mapping: ["custom1", "account.custom1"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 2",
    id: "custom2",
    mapping: ["custom2", "account.custom2"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 3",
    id: "custom3",
    mapping: ["custom3", "account.custom3"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 4",
    id: "custom4",
    mapping: ["custom4", "account.custom4"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 5",
    id: "custom5",
    mapping: ["custom5", "account.custom5"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 6",
    id: "custom6",
    mapping: ["custom6", "account.custom6"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 7",
    id: "custom7",
    mapping: ["custom7", "account.custom7"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 8",
    id: "custom8",
    mapping: ["custom8", "account.custom8"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 9",
    id: "custom9",
    mapping: ["custom9", "account.custom9"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 10",
    id: "custom10",
    mapping: ["custom10", "account.custom10"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 11",
    id: "custom11",
    mapping: ["custom11", "account.custom11"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 12",
    id: "custom12",
    mapping: ["custom12", "account.custom12"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 13",
    id: "custom13",
    mapping: ["custom13", "account.custom13"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 14",
    id: "custom14",
    mapping: ["custom14", "account.custom14"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Custom Field 15",
    id: "custom15",
    mapping: ["custom15", "account.custom15"],
    type: ["account", "accountSuppression"],
  },
  {
    label: "Id",
    id: "id",
    mapping: ["contact id", "id"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Account Id",
    id: "AccountId",
    mapping: ["accountId"],
    type: ["contact"],
  },
  {
    label: "Research Status",
    id: "researchStatus",
    mapping: ["researchStatus", "research_status", "status"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Calling Status",
    id: "callingStatus",
    mapping: ["callingStatus", "calling_status", "call status"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Compliance Status",
    id: "complianceStatus",
    mapping: ["complianceStatus"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Compliance Comments",
    id: "complianceComments",
    mapping: [
      "complianceComments",
      "compliance Comments",
      "compliance_comments",
    ],
    type: ["contact"],
  },
  {
    label: "Prefix",
    id: "prefix",
    mapping: ["prefix", "mr/miss/mrs", "gender"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "First Name",
    id: "firstName",
    mapping: ["firstName", "first_name", "first name"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Middle Name",
    id: "middleName",
    mapping: ["middleName", "middle_name", "middle name"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Last Name",
    id: "lastName",
    mapping: ["lastName", "last_name", "last name"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Address1",
    id: "address.street1",
    mapping: [
      "address1",
      "address_1",
      "address 1",
      "person street",
      "address",
      "address.street1",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Address2",
    id: "address.street2",
    mapping: ["address2", "address_2", "address 2", "address.street2"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "City",
    id: "address.city",
    mapping: ["city", "person city", "address.city"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "State",
    id: "address.state",
    mapping: ["state", "person state", "address.state"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Zip",
    id: "address.zipCode",
    mapping: ["zip", "person zip code", "address.zip", "address.zipCode"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Country",
    id: "address.country",
    mapping: ["country", "nation", "region", "address.country"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Home Address Street 1",
    id: "homeAddressStreet1",
    mapping: ["homeAddressStreet1"],
    type: ["contact"],
  },
  {
    label: "Home Address Street 2",
    id: "homeAddressStreet2",
    mapping: ["homeAddressStreet2"],
    type: ["contact"],
  },
  {
    label: "Home Address City",
    id: "homeAddressCity",
    mapping: ["homeAddressCity", "homeaddresscity"],
    type: ["contact"],
  },
  {
    label: "Home Address State",
    id: "homeAddressState",
    mapping: ["homeAddressState", "homeaddressstate"],
    type: ["contact"],
  },
  {
    label: "Home Address ZipCode",
    id: "homeAddressZipCode",
    mapping: ["homeAddressZipCode", "homeaddresszipCode"],
    type: ["contact"],
  },
  {
    label: "Home Address Country",
    id: "homeAddressCountry",
    mapping: ["homeAddressCountry", "homeaddresscountry"],
    type: ["contact"],
  },
  {
    label: "Email",
    id: "email",
    mapping: ["email", "email address"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Domain",
    id: "domain",
    mapping: ["domain", "contact.domain"],
    type: ["contact"],
  },
  {
    label: "Generic Email",
    id: "genericEmail",
    mapping: [
      "genericEmail",
      "generic_email",
      "generic email",
      "generic email address",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Phone",
    id: "phone",
    mapping: ["phone", "board line"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Direct Phone",
    id: "directPhone",
    mapping: [
      "directPhone",
      "direct dial",
      "direct phone",
      "direct phone number",
      "mobile/direct",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Mobile",
    id: "mobile",
    mapping: ["mobile", "mobile/direct"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Home Number",
    id: "homeNumber",
    mapping: ["homeNumber", "home_number", "homenumber"],
    type: ["contact"],
  },
  {
    label: "Mobile Number 1",
    id: "mobileNumber1",
    mapping: ["mobileNumber1", "mobile_number_1"],
    type: ["contact"],
  },
  {
    label: "Mobile Number 2",
    id: "mobileNumber2",
    mapping: ["mobileNumber2", "mobile_number_2"],
    type: ["contact"],
  },
  {
    label: "Job Title",
    id: "jobTitle",
    mapping: ["jobTitle", "job_title", "job title", "title"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Job Level",
    id: "jobLevel",
    mapping: [
      "level",
      "management_level",
      "contact level",
      "management level",
      "jobLevel",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Job Department",
    id: "jobDepartment",
    mapping: [
      "department",
      "contact_department",
      "contact department",
      "jobDepartment",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "NS ID",
    id: "nsId",
    mapping: ["nsId", "companyid", "company id", "account id"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Zoominfo Contact ID",
    id: "zoomInfoContactId",
    mapping: [
      "zoomInfoContactId",
      "zoomInfo contact id",
      "contact id",
      "contact_id",
      "record id",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Linkedin URL",
    id: "linkedInUrl",
    mapping: ["linkedInUrl"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Screen Shot",
    id: "screenshot",
    mapping: ["screenshot"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Handles",
    id: "handles",
    mapping: ["handles"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Website",
    id: "website",
    mapping: ["website"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Comments",
    id: "comments",
    mapping: ["comments"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Source",
    id: "source",
    mapping: ["source"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Stage",
    id: "stage",
    mapping: ["stage"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Functions",
    id: "functions",
    mapping: ["functions", "function", "job function"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Disposition",
    id: "disposition",
    mapping: ["disposition"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "ZB",
    id: "zb",
    mapping: ["zb", "zbounce", "zb status", "zero bounce"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "ZB Date & Time",
    id: "zbDateAndTime",
    mapping: [
      "zb date & time",
      "zbounce date & time",
      "zb status date & time",
      "zero bounce date & time",
      "zbDateAndTime",
    ],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Gmail Status",
    id: "gmailStatus",
    mapping: ["gmailStatus", "gmail_status", "gmail status", "gstatus"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Mail Tester Status",
    id: "mailTesterStatus",
    mapping: ["mailTesterStatus", "mailtester_status"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Company Name",
    id: "companyName",
    mapping: ["company name", "companyName"],
    type: ["contactSuppression"],
  },
  {
    label: "Company Website",
    id: "companyWebsite",
    mapping: ["company website", "companyWebsite", "account.website"],
    type: ["contactSuppression"],
  },
  {
    label: "Custom Field 1",
    id: "custom1",
    mapping: ["custom1"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 2",
    id: "custom2",
    mapping: ["custom2"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 3",
    id: "custom3",
    mapping: ["custom3"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 4",
    id: "custom4",
    mapping: ["custom4"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 5",
    id: "custom5",
    mapping: ["custom5"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 6",
    id: "custom6",
    mapping: ["custom6"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 7",
    id: "custom7",
    mapping: ["custom7"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 8",
    id: "custom8",
    mapping: ["custom8"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 9",
    id: "custom9",
    mapping: ["custom9"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 10",
    id: "custom10",
    mapping: ["custom10"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 11",
    id: "custom11",
    mapping: ["custom11"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 12",
    id: "custom12",
    mapping: ["custom12"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 13",
    id: "custom13",
    mapping: ["custom13"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 14",
    id: "custom14",
    mapping: ["custom14"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Custom Field 15",
    id: "custom15",
    mapping: ["custom15"],
    type: ["contact", "contactSuppression"],
  },
  {
    label: "Delivery Status",
    id: "deliveryStatus",
    mapping: ["deliveryStatus"],
    type: ["contact"],
  },
  {
    label: "Delivery Status",
    id: "deliveryStatus",
    mapping: ["deliveryStatus"],
    type: ["account"],
  },
  {
    label: "Task.Id", // added task prefix so that users know id Refers to task's id
    id: "id",
    mapping: ["taskid", "task id", "task.id"],
    type: ["task"],
  },
  {
    label: "Account/Contact Id",
    id: "ObjectId",
    mapping: ["objectId", "object Id", "object.id", "task.objectId", "id"],
    type: ["task"],
  },
  {
    label: "User Name",
    id: "userName",
    mapping: ["userName", "task.userName", "user name", "Agent", "Agents"],
    type: ["task"],
  },
  {
    label: "Object Type",
    id: "objectType",
    mapping: ["objectType", "object Type", "object.Type", "task.objectType"],
    type: ["task"],
  },
  {
    label: "Description",
    id: "description",
    mapping: ["description", "task.description"],
    type: ["task"],
  },
  {
    label: "Due Date",
    id: "dueDate",
    mapping: ["dueDate", "due Date", "task.dueDate"],
    type: ["task"],
  },
  {
    label: "Status",
    id: "status",
    mapping: ["status", "task.status"],
    type: ["task"],
  },
  {
    label: "Disposition",
    id: "disposition",
    mapping: ["disposition", "task.disposition"],
    type: ["task"],
  },
  {
    label: "Priority",
    id: "priority",
    mapping: ["priority", "task.priority"],
    type: ["task"],
  },
  {
    label: "Completed Date",
    id: "completedDate",
    mapping: ["completedDate", "task.completedDate"],
    type: ["task"],
  },
  {
    label: "Created At",
    id: "createdAt",
    mapping: ["createdAt", "createdDt"],
    type: ["contact"],
  },
];
